import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const SurveyToConnect = () => {
  const headingbanner = {
    title: `Survey2Connect`,
    content: `A Research Tech Platform`,
  };

  const data = {
    images: ["survey.webp", "survey-2.webp"],
    firstAlt: "UI UX Design To Create Survey",
    firstTitle: "Survey Creation Screen",
    secondAlt: "Ui Design To Create Questionnaire For Survey",
    secondTitle: "Questionnaire For Survey",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Website", "Web Application", "Mobile Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Surveys are an effective way to gather information and feedback from a group of people. However, creating and conducting a survey manually can be a time-consuming and challenging task. `,
          `A survey builder can solve this problem by providing an easy-to-use platform for designing, distributing and analyzing surveys.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `This platform help users to create surveys quickly and efficiently, without the need for specialised knowledge or training.`,
          `It provides a wide range of question types and customisable templates, making it easy for users to design surveys that are tailored to their specific needs.`,
          `It also help users to distribute their surveys to a wider audience, through various channels such as email, social media, and website embeds. Moreover, it offers analytics that allow users to easily analyse and visualise their survey results. `,
          `This will help users gain valuable insights and make data-driven decisions based on the feedback they receive.`,
        ],
      },
    ],
    image: ["survey-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: `A Wysiwyg Survey Builder`,
    para: [
      `WYSIWYG stands for "What You See Is What You Get". Using this approach, the platform allows users to create and edit digital content using a visual interface that closely resembles the final output.`,
    ],
    content: {
      imageAlt: "UI UX of Survey Builder to with various question types",
      imageTitle: "Survey Builder",
      image: ["survey-projectone.webp"],
      text: `You get multiple options to create a survey such as using different question types, matrix, automation settings, etc. to create innovative and customer-experience centric surveys to investigate your business challenges, analyse and explore viable solutions, and devise groundbreaking strategies.`,
    },
    content2: {
      imageAlt: "UI Screen to build own survey or selecting from library",
      imageTitle: "Build Your Own Survey",
      image: ["survey-projectone-2.webp"],
      text: `We have also provided multiple in-built component options, question choices, etc. Additionally, to improve your survey creation experience we have provided an option to choose the components from your library as well.`,
    },
  };

  const projectData2 = {
    title: `Themes for Every Mood`,
    para: [
      `Our wide theme library offers a diverse range of options, each designed to evoke a different emotion or atmosphere. `,
      `Whether you're feeling bold and adventurous or calm and composed, it has a theme that can help set the tone for your content and create an engaging visual experience for your audience.`,
    ],
    content: {
      image: ["survey-projecttwo.webp"],
      imageAlt: "UI Screen to build survey using default themes",
      imageTitle: "Build Survey with Default Theme",
    },
  };

  const projectData3 = {
    title: `Build, Set, Apply & Go`,
    para: [
      `You can use different set of conditions to create personalised surveys, choose your language, define the time to answer each question, etc or use in-built templates for prompt survey building.`,
    ],
    content: {
      image: [
        "survey-projectthree.webp",
        "survey-projectthree-2.webp",
        "survey-projectthree-3.webp",
        "survey-projectthree-4.webp",
        "survey-projectthree-5.webp",
        "survey-projectthree-6.webp",
        "survey-projectthree-7.webp",
        "survey-projectthree-8.webp",
      ],
      firstAlt: "Build Survey by using conditions",
      firstTitle: "Delayed Branching",
      secondAlt: "Build your Survey using basic and advanced settings",
      secondTitle: "Survey Settings",
      thirdAlt: "Set Survey Completion Rules",
      thirdTitle: "Completion Rules",
      fourthAlt: "In-built templates for Survey Creation",
      fourthTitle: "Survey Templates",
      fifthAlt: "Select Timer for each question",
      fifthTitle: "Timer for Survey Questions",
      sixthAlt: "Select Report Type for the Survey",
      sixthTitle: "Report Type",
      seventhAlt: "Chat Bot Assistance to Build Survey",
      seventhTitle: "Chat Bot Assistance",
      eighthAlt: "Preview Screen after Building the Survey",
      eighthTitle: "Preview Screen",
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, Survey2Connect offers an easy and effective way to create surveys that can help organisations gather valuable insights and feedback from their audience.`,
        `With their user-friendly interfaces and a wide range of customisation options, survey builders make it easy for anyone to create professional-looking surveys that are engaging and easy to use.`,
        `By leveraging the power of survey builders, businesses, researchers, and organisations of all sizes can collect the data they need to make informed decisions, improve their products and services, and gain a competitive edge in their respective industries.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={2} />
      </div>
    </Layout>
  );
};

export default SurveyToConnect;

export const Head = () => (
  <Seo
    title="Survey2Connect - Octet Design Studio"
    description="We offered User Research, UI UX designing & UI Development services to an enterprise CX platform. We also automated their CMS & solved their design gaps."
  />
);
